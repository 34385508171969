<template>
  <svg viewBox="0 0 210 210"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1596_26011)">
      <path d="M104.12 -0.000119799C48.0801 0.45988 1.87007 45.5099 0.0600683 101.52C-0.629932 122.89 5.07007 142.88 15.3901 159.74C15.7601 160.34 15.8701 161.07 15.6901 161.75L4.72007 202.55C4.39007 203.77 5.51007 204.88 6.73007 204.54L46.7401 193.39C47.4401 193.19 48.2001 193.31 48.8101 193.7C65.1501 204.08 84.5501 210.06 105.36 210C163.54 209.81 210.83 161.63 210 103.46C209.16 45.8899 162 -0.47012 104.12 -0.000119799Z"
            fill="#FCEF53"
      />
      <path d="M51.5802 91.1399L61.2902 117.21L55.5202 119.36L51.4402 108.41L40.6002 112.45L44.6802 123.4L38.9102 125.55L29.2002 99.4799L34.9702 97.3299L38.5802 107.02L49.4202 102.98L45.8102 93.2899L51.5802 91.1399Z"
            fill="#7856A4"
      />
      <path d="M103.9 110.5L105.86 115.44L86.3001 123.19L76.8501 99.3399L96.1801 91.6799L98.1401 96.6199L85.7301 101.54L87.2101 105.29L98.3701 100.87L100.27 105.67L89.1101 110.09L91.2601 115.51L103.9 110.5Z"
            fill="#7856A4"
      />
      <path d="M140.07 109.96L143.61 119.37L137.84 121.54L134.33 112.2L118.68 99.0201L125.6 96.4201L134.98 105.34L135.05 105.31L136.27 92.4101L143.19 89.8101L140.06 109.97L140.07 109.96Z"
            fill="#7856A4"
      />
      <path d="M180.88 116.39C181.61 118.37 180.61 120.47 178.68 121.19C176.75 121.91 174.61 120.96 173.88 118.99C173.15 117.02 174.11 114.92 176.08 114.19C178.05 113.46 180.15 114.42 180.88 116.39ZM177.4 111.58L173.37 113.07L165.58 95.8199L172.03 93.4299L177.4 111.58Z"
            fill="#7856A4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1596_26011">
        <rect width="210"
              height="210"
              fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
